import PrefilledRecipientIdentityFailedPageWrapper from 'components/page-components/disclaimer-pages/prefilled-recipient-identity-failed-page-component/PrefilledRecipientIdentityFailedPageWrapper';
import TrustFundedPrearrangementPageWrapper from 'components/page-components/disclaimer-pages/trust-funded-prearrangement-page-component/TrustFundedPrearrangementPageWrapper';
import VerifyPurchaserIdentityPageWrapper from 'components/page-components/disclaimer-pages/verify-purchaser-identity-page-component/VerifyPurchaserIdentityPageWrapper';
import DocusignFreeContractPageWrapper from 'components/page-components/docusign-free-contract-page-component/DocusignFreeContractPageWrapper';
import SendingTestFailingRequestWrapper from 'components/page-components/error-page-component/sending-failing-request-component/SendingTestFailingRequestWrapper';
import ErrorSubmissionPageWrapper from 'components/page-components/error-submission-page-component/ErrorSubmissionPageWrapper';
import InvalidContractSigningCredentialsPageWrapper from 'components/page-components/invalid-contract-signing-credentials/InvalidContractSigningCredentialsPageWrapper';
import IrrevocablePageWrapper from 'components/page-components/irrevocable-page-component/IrrevocablePageWrapper';
import OpportunityStatusPageWrapper from 'components/page-components/opportunity-status-page-component/OpportunityStatusPageWrapper';
import PurchaseProcessingBlankPageWrapper from 'components/page-components/purchase-processing-blank-page-component/PurchaseProcessingBlankPageWrapper';
import PurchaseProcessingNavigationPageWrapper from 'components/page-components/purchase-processing-navigation-page-component/PurchaseProcessingNavigationPageWrapper';
import ReplacementPageWrapper from 'components/page-components/replacement-page-component/ReplacementPageWrapper';
import RepresentativeCompletePageWrapper from 'components/page-components/representative-complete-page-component/RepresentativeCompletePageWrapper';
import SecureLinkSignPageWrapper from 'components/page-components/secure-link-sign-page-component/SecureLinkSignPageWrapper';
import SecureSignCompletePageWrapper from 'components/page-components/secure-sign-complete-page-component/SecureSignCompletePageWrapper';
import StartPlanningPageWrapper from 'components/page-components/start-planning-page-component/StartPlanningPageWrapper';
import React from 'react';
import { Route } from 'react-router-dom';
import urlPaths from '../../static/constants/enums/urlPaths';
import BeneficiaryCheckPageWrapper from '../page-components/beneficiary-check-page-component/BeneficiaryCheckPageWrapper';
import BeneficiaryPageWrapper from '../page-components/beneficiary-page-component/BeneficiaryPageWrapper';
import ContractsDeniedPageWrapper from '../page-components/contracts-denied-page-component/ContractsDeniedPageWrapper';
import DemographicsInstructionsPageWrapper from '../page-components/demograpics-instructions-page-component/DemographicsInstructionsPageWrapper';
import ProductDisclaimerPageWrapper from '../page-components/disclaimer-pages/annuity-agreement-page-component/ProductDisclaimerPageWrapper';
import PrefilledRecipientAgreementPageWrapper from '../page-components/disclaimer-pages/prefilled-recipient-agreement-page-component/PrefilledRecipientAgreementPageWrapper';
import generatePriceListDisclaimer from '../page-components/disclaimer-pages/price-list-disclaimer-component-page-component/PriceListDisclaimerPageWrapper';
import RecipientAgreementPageWrapper from '../page-components/disclaimer-pages/recipient-agreement-page-component/RecipientAgreementPageWrapper';
import RecipientIdentityFailedPageWrapper from '../page-components/disclaimer-pages/recipient-identity-failed-page-component/RecipientIdentityFailedPageWrapper';
import RecipientUnavailableImpactPageWrapper from '../page-components/disclaimer-pages/recipient-unavailable-impact-page-component/RecipientUnavailableImpactPageWrapper';
import StateFilingFeePageWrapper from '../page-components/disclaimer-pages/state-filing-fee-page-component/StateFilingFeePageWrapper';
import DocusignReroutePageWrapper from '../page-components/docusign-reroute-page-component/DocusignReroutePageWrapper';
import ErrorPageWrapper from '../page-components/error-page-component/ErrorPageWrapper';
import ForceNavigationPageWrapper from '../page-components/force-navigation-page-component/ForceNavigationPageWrapper';
import FuneralAgentStartPageWrapper from '../page-components/funeral-agent-start-page-component/FuneralAgentStartPageWrapper';
import FuneralContractsPageWrapper from '../page-components/funeral-contracts-page-component/FuneralContractsPageWrapper';
import LoadFromGuidedPageWrapper from '../page-components/load-from-guided-page-component/LoadFromGuidedPageWrapper';
import LoadOpportunityPageWrapper from '../page-components/load-opportunity-page-component/LoadOpportunityPageWrapper';
import NoAvailableFundingProductPageWrapper from '../page-components/no-available-funding-product-page-component/NoAvailableFundingProductPageWrapper';
import NoPaymentOptionsPageWrapper from '../page-components/no-payment-options-page-component/NoPaymentOptionsPageWrapper';
import OpportunityClosedPageWrapper from '../page-components/opportunity-closed-page-component/OpportunityClosedPageWrapper';
import OrderSummaryPageWrapper from '../page-components/order-summary-page-component/OrderSummaryPageWrapper';
import PaymentPageVersionPickerWrapper from '../page-components/payment-page-component/PaymentPageVersionPickerWrapper';
import PrefilledRecipientGratitudePageWrapper from '../page-components/prefilled-recipient-gratitude-page-component/PrefilledRecipientGratitudePageWrapper';
import PrefilledRecipientIdentityPageWrapper from '../page-components/prefilled-recipient-identity-page-component copy/PrefilledRecipientIdentityPageWrapper';
import ProductSelectionPageWrapper from '../page-components/product-selection-page-component/ProductSelectionPageWrapper';
import PurchaseProcessingPageWrapper from '../page-components/purchase-processing-page-component/PurchaseProcessingPageWrapper';
import IdentityConfirmationPageWrapper from '../page-components/purchaser-identity-confirmation-page-component/PurchaserIdentityPageWrapper';
import PurchaserOrderSummaryPageWrapper from '../page-components/purchaser-order-summary-page-component/PurchaserOrderSummaryPageWrapper';
import PurchaserPageWrapper from '../page-components/purchaser-page-component/PurchaserPageWrapper';
import PurchaserPhoneVerificationRequestPageWrapper from '../page-components/purchaser-phone-verification-request-page-component/PurchaserPhoneVerificationRequestPageWrapper';
import PurchaserPhoneVerifyPageWrapper from '../page-components/purchaser-phone-verify-page-component/PurchaserPhoneVerifyPageWrapper';
import PurchaserSaleCompletePageWrapper from '../page-components/purchaser-sale-complete-page-component/PurchaserSaleCompletePageWrapper';
import PurchaserSigningCompletePageWrapper from '../page-components/purchaser-signing-complete-page-component/PurchaserSigningCompletePageWrapper';
import SubdomainSelectionPageWrapper from '../page-components/purchaser-start-page-component/PurchaserStartPageWrapper';
import RecipientAvailabilityCheckPageWrapper from '../page-components/recipient-availability-check-page-component/RecipientAvailabilityCheckPageWrapper';
import RecipientCheckPageWrapper from '../page-components/recipient-check-page-component/RecipientCheckPageWrapper';
import RecipientGratitudePageWrapper from '../page-components/recipient-gratitude-page-component/RecipientGratitudePageWrapper';
import RecipientIdentityPageWrapper from '../page-components/recipient-identity-page-component/RecipientIdentityPageWrapper';
import RecipientPageWrapper from '../page-components/recipient-page-component/RecipientPageWrapper';
import RelationshipToRecipientPageWrapper from '../page-components/relationship-to-recipient-page-component/RelationshipToRecipientPageWrapper';
import RepresentativeIdentityPageWrapper from '../page-components/representative-identity-page-component/RepresentativeIdentityPageWrapper';
import SecurityCheckPageWrapper from '../page-components/security-check-page-component/SecurityCheckPageWrapper';
import SendIdentityLinkEmailPageWrapper from '../page-components/send-identity-link-email-page-component/SendIdentityLinkEmailPageWrapper';
import SSNCapturePageWrapper from '../page-components/ssn-capture-page-component/SSNCapturePageWrapper';
import TravelContractsPageWrapper from '../page-components/travel-contracts-page-component/TravelContractsPageWrapper';
import FuneralDirectorStatePageWrapper from '../page-components/unavailable-pages/funeral-director-state-page-component/FuneralDirectorStatePageWrapper';
import IdentityFailedPageWrapper from '../page-components/unavailable-pages/identity-failed-page-component/IdentityFailedPageWrapper';
import PurchaserUnderagePageWrapper from '../page-components/unavailable-pages/purchaser-underage-page-component/UnderagePurchaserPageWrapper';
import RecipientRelationshipNotListedPageWrapper from '../page-components/unavailable-pages/recipient-relationship-not-listed-page-component/RecipientRelationshipNotListedPageWrapper';
import ReplacementSorryPageWrapper from '../page-components/unavailable-pages/replacement-sorry-page-component/ReplacementSorryPageWrapper';
import UnderageRecipientPageWrapper from '../page-components/unavailable-pages/template-component/UnderageRecipientPageWrapper';
import VitalStatisticsPageWrapper from '../page-components/vital-statistics-page-component/VitalStatisticsPageWrapper';
import VitalStatisticsSavedWrapper from '../page-components/vital-statistics-saved-component/VitalStatisticsSavedWrapper';
import './RoutingComponent.css';
import RecipientIdentityFailedWithPaymentHoldPageWrapper from 'components/page-components/unavailable-pages/recipient-identity-failed-with-payment-hold-page-component/RecipientIdentityFailedWithPaymentHoldPageWrapper';

export default [
    <Route key="forceNavigation" path={urlPaths.forceNavigation} component={ForceNavigationPageWrapper}></Route>,
    <Route key="error" path={urlPaths.error} component={ErrorPageWrapper}></Route>,
    <Route key="errorSubmission" path={urlPaths.errorSubmission} component={ErrorSubmissionPageWrapper}></Route>,
    <Route key="securityCheck" path={urlPaths.securityCheck} component={SecurityCheckPageWrapper}></Route>,
    <Route key="loadOpportunity" path={urlPaths.loadOpportunity} component={LoadOpportunityPageWrapper}></Route>,
    <Route key="subdomainSelection" path={urlPaths.purchaserStart} component={SubdomainSelectionPageWrapper} exact />,
    <Route key="agentStart" path={urlPaths.agentStart} component={FuneralAgentStartPageWrapper} exact />,
    <Route key="loadFromGuided" path={urlPaths.loadFromGuided} component={LoadFromGuidedPageWrapper} exact></Route>,
    <Route key="productSelection" path={urlPaths.productSelection} component={ProductSelectionPageWrapper} exact />,
    <Route key="ssnCapture" path={urlPaths.ssnCapture} component={SSNCapturePageWrapper} exact></Route>,
    <Route key="startPlanning" path={urlPaths.startPlanning} sensitive={false} component={StartPlanningPageWrapper} />,
    <Route
        key="sendingTestFailingRequest"
        path={urlPaths.sendTestFailingRequest}
        exact
        component={SendingTestFailingRequestWrapper}
    />,
    <Route
        key="verifyPurchaserIdentity"
        path={urlPaths.verifyPurchaserIdentity}
        component={VerifyPurchaserIdentityPageWrapper}
        exact
    ></Route>,
    <Route
        key="earlyPriceListDisclaimer"
        path={urlPaths.earlyPriceListDisclaimer}
        component={generatePriceListDisclaimer(urlPaths.demographicsInstructions)}
        exact
    />,
    <Route
        key="trustFundedPrearrangement"
        path={urlPaths.trustFundedPrearrangement}
        component={TrustFundedPrearrangementPageWrapper}
        exact
    />,
    <Route
        key="demographicsInstructions"
        path={urlPaths.demographicsInstructions}
        exact
        component={DemographicsInstructionsPageWrapper}
    />,
    <Route key="purchaser" path={urlPaths.purchaser} exact component={PurchaserPageWrapper} />,
    <Route
        key="unavailable-funeralDirectorState"
        path={urlPaths.unavailableFuneralDirectorState}
        exact
        component={FuneralDirectorStatePageWrapper}
    />,
    <Route
        key="unavailable-underagePurchaser"
        path={urlPaths.underagePurchaser}
        exact
        component={PurchaserUnderagePageWrapper}
    />,
    <Route key="recipientCheck" path={urlPaths.recipientCheck} exact component={RecipientCheckPageWrapper} />,
    <Route
        key="relationshipToRecipient"
        path={urlPaths.relationshipToRecipient}
        exact
        component={RelationshipToRecipientPageWrapper}
    />,
    <Route
        key="recipientRelationshipNotListed"
        path={urlPaths.recipientRelationshipNotListed}
        exact
        component={RecipientRelationshipNotListedPageWrapper}
    />,
    <Route key="recipient" path={urlPaths.recipient} exact component={RecipientPageWrapper} />,
    <Route
        key="unavailable-underageRecipient"
        path={urlPaths.underageRecipient}
        exact
        component={UnderageRecipientPageWrapper}
    />,
    <Route
        key="disclaimer-annuityInsurance"
        path={urlPaths.disclaimerProduct}
        exact
        component={ProductDisclaimerPageWrapper}
    />,
    <Route
        key="noFundingProductAvailable"
        path={urlPaths.noFundingProductAvailable}
        exact
        component={NoAvailableFundingProductPageWrapper}
    />,
    <Route
        key="unavailable-replacementState"
        path={urlPaths.unavailableReplacementState}
        exact
        component={ReplacementSorryPageWrapper}
    />,
    <Route key="recipientIdentity" path={urlPaths.recipientIdentity} exact component={RecipientIdentityPageWrapper} />,
    <Route
        key="recipientIdentityFailed"
        path={urlPaths.recipientIdentityFailed}
        exact
        component={RecipientIdentityFailedPageWrapper}
    />,
    <Route
        key="recipientIdentityFailedHoldPlaced"
        path={urlPaths.recipientIdentityFailedHoldPlaced}
        exact
        component={RecipientIdentityFailedWithPaymentHoldPageWrapper}
    />,
    <Route
        key="recipientAvailabilityCheck"
        path={urlPaths.recipientAvailabilityCheck}
        exact
        component={RecipientAvailabilityCheckPageWrapper}
    />,
    <Route
        key="recipientAbsent"
        path={urlPaths.recipientAbsent}
        exact
        component={RecipientUnavailableImpactPageWrapper}
    />,
    <Route key="recipientConsent" path={urlPaths.recipientConsent} exact component={RecipientAgreementPageWrapper} />,
    <Route
        key="recipientGratitude"
        path={urlPaths.recipientGratitude}
        exact
        component={RecipientGratitudePageWrapper}
    />,
    <Route key="stateFilingFee" path={urlPaths.stateFilingFee} exact component={StateFilingFeePageWrapper} />,
    <Route
        key="middlePriceListDisclaimer"
        path={urlPaths.middlePriceListDisclaimer}
        component={generatePriceListDisclaimer(urlPaths.orderSummary)}
        exact
    />,
    <Route key="noPaymentOptions" path={urlPaths.noPaymentOptions} exact component={NoPaymentOptionsPageWrapper} />,
    <Route key="orderSummary" path={urlPaths.orderSummary} exact component={OrderSummaryPageWrapper} />,
    <Route key="payment" path={urlPaths.payment} exact component={PaymentPageVersionPickerWrapper} />,
    <Route key="beneficiaryCheck" path={urlPaths.beneficiaryCheck} exact component={BeneficiaryCheckPageWrapper} />,
    <Route key="beneficiary" path={urlPaths.beneficiary} exact component={BeneficiaryPageWrapper} />,
    <Route
        key="latePriceListDisclaimer"
        path={urlPaths.latePriceListDisclaimer}
        component={generatePriceListDisclaimer(urlPaths.purchaserOrderSummary)}
        exact
    />,
    <Route
        key="purchaserOrderSummary"
        path={urlPaths.purchaserOrderSummary}
        exact
        component={PurchaserOrderSummaryPageWrapper}
    />,
    <Route
        key="prefilledRecipientIdentity"
        path={urlPaths.prefilledRecipientIdentity}
        exact
        component={PrefilledRecipientIdentityPageWrapper}
    />,
    <Route
        key="prefilledRecipientIdentityFailed"
        path={urlPaths.prefilledRecipientIdentityFailed}
        exact
        component={PrefilledRecipientIdentityFailedPageWrapper}
    />,
    <Route
        key="prefilledRecipientConsent"
        path={urlPaths.prefilledRecipientConsent}
        exact
        component={PrefilledRecipientAgreementPageWrapper}
    />,
    <Route
        key="prefilledRecipientGratitude"
        path={urlPaths.prefilledRecipientGratitude}
        exact
        component={PrefilledRecipientGratitudePageWrapper}
    />,
    <Route
        key="purchaserIdentity"
        path={urlPaths.purchaserIdentity}
        exact
        component={IdentityConfirmationPageWrapper}
    />,
    <Route
        key="purchaserPhoneVerificationRequest"
        path={urlPaths.purchaserPhoneVerificationRequest}
        exact
        component={PurchaserPhoneVerificationRequestPageWrapper}
    />,
    <Route
        key="purchaserPhoneVerify"
        path={urlPaths.purchaserPhoneVerify}
        exact
        component={PurchaserPhoneVerifyPageWrapper}
    />,
    <Route
        key="representativeIdentity"
        path={urlPaths.representativeIdentity}
        exact
        component={RepresentativeIdentityPageWrapper}
    />,
    <Route
        key="sendIdentityLinkEmail"
        path={urlPaths.sendIdentityLinkEmail}
        exact
        component={SendIdentityLinkEmailPageWrapper}
    />,
    <Route
        key="representativeComplete"
        path={urlPaths.representativeComplete}
        exact
        component={RepresentativeCompletePageWrapper}
    />,
    <Route
        key="unavailable-identityFailed"
        path={urlPaths.unavailableIdentityFailed}
        exact
        component={IdentityFailedPageWrapper}
    />,
    <Route key="docusignReroute" path={urlPaths.docusignReroute} exact component={DocusignReroutePageWrapper} />,
    <Route key="travelContracts" path={urlPaths.travelContracts} exact component={TravelContractsPageWrapper} />,
    <Route key="funeralContracts" path={urlPaths.funeralContracts} exact component={FuneralContractsPageWrapper} />,
    <Route key="contractsDenied" path={urlPaths.contractsDenied} exact component={ContractsDeniedPageWrapper} />,
    <Route
        key="purchaserSaleComplete"
        path={urlPaths.purchaserSaleComplete}
        exact
        component={PurchaserSaleCompletePageWrapper}
    />,
    <Route
        key="docusignFreeContractPage"
        path={urlPaths.docusignFreeContractPage}
        exact
        component={DocusignFreeContractPageWrapper}
    />,
    <Route
        key="purchaserSigningComplete"
        path={urlPaths.purchaserSigningComplete}
        exact
        component={PurchaserSigningCompletePageWrapper}
    />,
    <Route
        key="purchaseProcessing"
        path={urlPaths.purchaseProcessing}
        exact
        component={PurchaseProcessingPageWrapper}
    />,
    <Route
        key="purchaseProcessingNavigation"
        path={urlPaths.purchaseProcessingNavigation}
        exact
        component={PurchaseProcessingNavigationPageWrapper}
    />,
    <Route
        key="purchaseProcessingBlank"
        path={urlPaths.purchaseProcessingBlank}
        exact
        component={PurchaseProcessingBlankPageWrapper}
    />,
    <Route key="expired" path={urlPaths.expired} exact component={OpportunityClosedPageWrapper} />,
    <Route key="vitalStatistics" path={urlPaths.vitalStatistics} exact component={VitalStatisticsPageWrapper} />,
    <Route
        key="vitalStatisticsSaved"
        path={urlPaths.vitalStatisticsSaved}
        exact
        component={VitalStatisticsSavedWrapper}
    />,
    <Route key="secureLinkSigning" path={urlPaths.secureLinkSigning} exact component={SecureLinkSignPageWrapper} />,
    <Route
        key="secureLinkSigningComplete"
        path={urlPaths.secureLinkSigningComplete}
        exact
        component={SecureSignCompletePageWrapper}
    />,
    <Route key="opportunityStatus" path={urlPaths.opportunityStatus} exact component={OpportunityStatusPageWrapper} />,
    <Route
        key="invalidContractSigningCredentials"
        path={urlPaths.invalidContractSigningCredentials}
        exact
        component={InvalidContractSigningCredentialsPageWrapper}
    />,
    <Route key="irrevocable" path={urlPaths.irrevocable} exact component={IrrevocablePageWrapper} />,
    <Route key="replacement" path={urlPaths.replacement} exact component={ReplacementPageWrapper} />
];
