import GlobalStateShape from 'redux/GlobalStateShape';
import { FundingProductType } from 'static/constants/enums/fundingProductType';
import { GeographicState } from 'static/constants/enums/states';
import { Timing } from 'static/constants/enums/timing';
import replacementStates from 'static/constants/stateSpecificLists/replacementStates';
import annuityReplacementStates from 'static/constants/stateSpecificLists/annuityReplacementStates';
import { getFormData } from 'toolboxes/redux-form/redux-form-data-access';
import { isAtNeed } from './opportunity-timing-utils';

export function showReplacementCalculator(state: GlobalStateShape): boolean {
    const isProperFundingProductType =
        state.opportunity?.selectedFundingProduct?.productType !== FundingProductType.Trust;
    const isNotAtNeed = !isAtNeed(state);
    const isInReplacementState = calculateIsInReplacementState(state);
    return isProperFundingProductType && isNotAtNeed && isInReplacementState;
}

function calculateIsInReplacementState(state: GlobalStateShape): boolean {
    const isInReplacementState =
        state.opportunity.selectedFundingProduct.productType == FundingProductType.Annuity
            ? annuityReplacementStates.includes(getFormData(state, 'purchaser', 'state'))
            : replacementStates.includes(getFormData(state, 'purchaser', 'state'));

    const matchesStateSpecificReplacementRule = calculateStateSpecificReplacementRules(state);
    return isInReplacementState || matchesStateSpecificReplacementRule;
}

function calculateStateSpecificReplacementRules(state: GlobalStateShape) {
    const matchesCAReplacementRule =
        state.opportunity?.timing === Timing.IMMINENT &&
        getFormData(state, 'purchaser', 'state') === GeographicState.CA;
    return matchesCAReplacementRule;
}
