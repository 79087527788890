import GlobalStateShape from 'redux/GlobalStateShape';
import { contractsAreComplete } from 'toolboxes/reuseable-logic/contractCompleteness';
import urlPaths from './enums/urlPaths';
import { EARTH_STYLING_KEY } from './styling-company-constants/companies/earth';

// Once an opportunity has entered a new phase, it should not be able to go back to any prior phases

const nonPhase = [
    urlPaths.forceNavigation,
    urlPaths.error,
    urlPaths.securityCheck,
    urlPaths.loadOpportunity,
    urlPaths.docusignReroute,
    urlPaths.expired,
    urlPaths.agentStart,
    urlPaths.productSelection,
    urlPaths.purchaserStart,
    urlPaths.secureLinkSigning,
    urlPaths.invalidContractSigningCredentials,
    urlPaths.secureLinkSigningComplete,
    urlPaths.loadFromGuided,
    urlPaths.startPlanning,
    urlPaths.errorSubmission,
    urlPaths.sendTestFailingRequest,
    urlPaths.opportunityStatus,
    urlPaths.irrevocable,
    urlPaths.replacement
];

const phaseOne = [
    urlPaths.demographicsInstructions,
    urlPaths.purchaser,
    urlPaths.recipientCheck,
    urlPaths.underagePurchaser,
    urlPaths.unavailableFuneralDirectorState,
    urlPaths.noFundingProductAvailable,
    urlPaths.recipientRelationshipNotListed,
    urlPaths.relationshipToRecipient,
    urlPaths.recipient,
    urlPaths.recipientIdentity,
    urlPaths.recipientAbsent,
    urlPaths.recipientAvailabilityCheck,
    urlPaths.recipientConsent,
    urlPaths.recipientGratitude,
    urlPaths.underageRecipient,
    urlPaths.trustFundedPrearrangement,
    urlPaths.disclaimerProduct,
    urlPaths.beneficiaryCheck,
    urlPaths.beneficiary,
    urlPaths.stateFilingFee,
    urlPaths.orderSummary,
    urlPaths.unavailableReplacementState,
    urlPaths.noFundingProductAvailable,
    urlPaths.noPaymentOptions,
    urlPaths.payment,
    urlPaths.recipientIdentityFailed,
    urlPaths.earlyPriceListDisclaimer,
    urlPaths.middlePriceListDisclaimer
];

const phaseTwo = [
    urlPaths.purchaserOrderSummary,
    urlPaths.purchaserIdentity,
    urlPaths.purchaserPhoneVerificationRequest,
    urlPaths.purchaserPhoneVerify,
    urlPaths.prefilledRecipientIdentity,
    urlPaths.prefilledRecipientConsent,
    urlPaths.prefilledRecipientGratitude,
    urlPaths.prefilledRecipientIdentityFailed,
    urlPaths.representativeIdentity,
    urlPaths.representativeComplete,
    urlPaths.sendIdentityLinkEmail,
    urlPaths.travelContracts,
    urlPaths.funeralContracts,
    urlPaths.unavailableIdentityFailed,
    urlPaths.purchaserSigningComplete,
    urlPaths.contractsDenied,
    urlPaths.latePriceListDisclaimer,
    urlPaths.ssnCapture,
    urlPaths.verifyPurchaserIdentity,
    urlPaths.docusignFreeContractPage
];

const phaseThree = [
    urlPaths.purchaseProcessing,
    urlPaths.purchaseProcessingBlank,
    urlPaths.purchaseProcessingNavigation,
    urlPaths.vitalStatistics,
    urlPaths.vitalStatisticsSaved,
    urlPaths.purchaserSaleComplete
];

const earthPhaseOne = [
    urlPaths.demographicsInstructions,
    urlPaths.purchaser,
    urlPaths.recipientCheck,
    urlPaths.underagePurchaser,
    urlPaths.unavailableFuneralDirectorState,
    urlPaths.noFundingProductAvailable,
    urlPaths.recipientRelationshipNotListed,
    urlPaths.relationshipToRecipient,
    urlPaths.recipient,
    urlPaths.recipientIdentity,
    urlPaths.recipientIdentityFailed,
    urlPaths.recipientIdentityFailedHoldPlaced,
    urlPaths.recipientAbsent,
    urlPaths.recipientAvailabilityCheck,
    urlPaths.recipientConsent,
    urlPaths.recipientGratitude,
    urlPaths.underageRecipient,
    urlPaths.beneficiaryCheck,
    urlPaths.beneficiary,
    urlPaths.stateFilingFee,
    urlPaths.noFundingProductAvailable,
    urlPaths.noPaymentOptions,
    urlPaths.payment,
    urlPaths.earlyPriceListDisclaimer,
    urlPaths.middlePriceListDisclaimer
];

const earthPhaseTwo = [
    urlPaths.purchaserOrderSummary,
    urlPaths.trustFundedPrearrangement,
    urlPaths.disclaimerProduct,
    urlPaths.orderSummary,
    urlPaths.purchaserIdentity,
    urlPaths.purchaserPhoneVerificationRequest,
    urlPaths.purchaserPhoneVerify,
    urlPaths.prefilledRecipientIdentity,
    urlPaths.prefilledRecipientConsent,
    urlPaths.prefilledRecipientGratitude,
    urlPaths.prefilledRecipientIdentityFailed,
    urlPaths.representativeIdentity,
    urlPaths.representativeComplete,
    urlPaths.sendIdentityLinkEmail,
    urlPaths.travelContracts,
    urlPaths.funeralContracts,
    urlPaths.unavailableIdentityFailed,
    urlPaths.purchaserSigningComplete,
    urlPaths.contractsDenied,
    urlPaths.latePriceListDisclaimer,
    urlPaths.ssnCapture,
    urlPaths.verifyPurchaserIdentity,
    urlPaths.docusignFreeContractPage,
    urlPaths.irrevocable,
    urlPaths.replacement,
    urlPaths.unavailableReplacementState
];

const earthPhaseThree = [
    urlPaths.purchaseProcessing,
    urlPaths.purchaseProcessingBlank,
    urlPaths.purchaseProcessingNavigation,
    urlPaths.vitalStatistics,
    urlPaths.vitalStatisticsSaved,
    urlPaths.purchaserSaleComplete
];

export const getPhaseRedirect = (state: GlobalStateShape, urlPath: string): string | void => {
    if (state.account.stylingKey === EARTH_STYLING_KEY) {
        if (earthPhaseOne.some(path => path.startsWith(urlPath)) && state.opportunity.pricing.paymentInitiated) {
            return earthPhaseTwo[0];
        }
        if (earthPhaseTwo.some(path => path.startsWith(urlPath)) && contractsAreComplete(state)) {
            return earthPhaseThree[0];
        }
    } else {
        if (phaseOne.some(path => path.startsWith(urlPath)) && state.opportunity.pricing.paymentInitiated) {
            return phaseTwo[0];
        }
        if (phaseTwo.some(path => path.startsWith(urlPath)) && contractsAreComplete(state)) {
            return phaseThree[0];
        }
    }
};

// Verify all urlPaths have been assigned to a phase
Object.keys(urlPaths).forEach(key => {
    if (nonPhase.includes(urlPaths[key])) {
        return;
    }
    if (earthPhaseOne.includes(urlPaths[key])) {
        return;
    }
    if (earthPhaseTwo.includes(urlPaths[key])) {
        return;
    }
    if (earthPhaseThree.includes(urlPaths[key])) {
        return;
    }
    console.error(
        `${key} was not put into any flow phases. This should be done so users can't change solidified data.`
    );
});

// Verify all urlPaths have been assigned to an earth phase
