import React from 'react';
import PageCardTitleWrapper from '../../../global-components/page-card-components/page-card-title-component/PageCardTitleWrapper';
import PageCardTextWrapper from '../../../global-components/page-card-components/page-card-text-component/PageCardTextWrapper';
import FullContentCenterWrapper from '../../../global-components/page-card-components/full-content-center-component/FullContentCenterWrapper';
import PageCardWrapper from '../../../global-components/page-card-components/page-card-component/PageCardWrapper';
import BackButtonLinkWrapper from '../../../global-components/buttons/back-button-link-component/BackButtonLinkWrapper';
import { formatPhoneNumber } from 'toolboxes/reuseable-logic/string-formatters';
class RecipientIdentityFailedWithPaymentHoldPageComponent extends React.Component<PropShape, StateShape> {
    constructor(props: PropShape) {
        super(props);
        this.state = {
            // Starting state values
        };
    }
    static defaultProps = {
        // Default prop values
        accountPhoneNumber: '',
        recipientFirstName: '',
        recipientLastName: ''
    };
    render() {
        return (
            <FullContentCenterWrapper
                metaPageName="RecipientIdentityFailedWithPaymentHold"
                metaPageNumber="1"
                metaPagebackAvailable="true"
            >
                <PageCardWrapper>
                    <PageCardTitleWrapper>We're Sorry</PageCardTitleWrapper>
                    <PageCardTextWrapper>
                        <>
                            <p>
                                We weren't able to digitally verify the identity for&nbsp;
                                {this.props.recipientFirstName} {this.props.recipientLastName}. Because of this, we are
                                unable to offer the payment options previously selected.
                            </p>
                            <p>
                                You can however, continue with your arrangement by paying in full. If you would like to
                                continue with paying in full, please call us at&nbsp;
                                {this.props.accountPhoneNumber
                                    ? ` directly at ${formatPhoneNumber(this.props.accountPhoneNumber)}`
                                    : ''}
                                .
                            </p>
                        </>
                    </PageCardTextWrapper>
                    <BackButtonLinkWrapper />
                </PageCardWrapper>
            </FullContentCenterWrapper>
        );
    }
}
export interface PropShape extends React.Props<any> {
    // Shape of passed in props (including redux dispatch functions)
    accountPhoneNumber: string;
    recipientFirstName: string;
    recipientLastName: string;
}
interface StateShape {
    // Shape of local state
}
export default RecipientIdentityFailedWithPaymentHoldPageComponent;
