import { connect } from 'react-redux';
import RecipientIdentityFailedWithPaymentHoldPageComponent from './RecipientIdentityFailedWithPaymentHoldPageComponent';
import GlobalStateShape from '../../../../redux/GlobalStateShape';
import { getFormData } from 'toolboxes/redux-form/redux-form-data-access';
const mapStateToProps: any = (state: GlobalStateShape) => {
    // These values will be passed in as props
    return {
        accountPhoneNumber: state.account.phone,
        recipientFirstName: getFormData(state, 'recipient', 'firstName'),
        recipientLastName: getFormData(state, 'recipient', 'lastName')
    };
};
const mapDispatchToProps: any = (dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(RecipientIdentityFailedWithPaymentHoldPageComponent);
