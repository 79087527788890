enum urlPaths {
    forceNavigation = '/forceNavigation',
    error = '/error',
    errorSubmission = '/errorSubmission',
    sendTestFailingRequest = '/sendTestFailingRequest',
    securityCheck = '/securityCheck',
    loadOpportunity = '/loadOpportunity',
    loadFromGuided = '/loadFromGuided',
    purchaserStart = '/c23697b7-4b23-4158-a2fe-2994674a26e9',
    agentStart = '/8c8c7a68-05ae-4ee9-8aed-f694cfb3940e',
    productSelection = '/507e95fb-1637-45c7-a99c-bfa1b68d51c1',
    demographicsInstructions = '/demographicsInstructions',
    purchaser = '/purchaser',
    unavailableFuneralDirectorState = '/unavailableFuneralDirectorState',
    recipientCheck = '/recipientCheck',
    relationshipToRecipient = '/relationshipToRecipient',
    recipientRelationshipNotListed = '/recipientRelationshipNotListed',
    recipient = '/recipient',
    disclaimerProduct = '/disclaimerProduct',
    unavailableReplacementState = '/unavailableReplacementState',
    orderSummary = '/orderSummary',
    payment = '/payment',
    beneficiaryCheck = '/beneficiaryCheck',
    beneficiary = '/beneficiary',
    purchaserIdentity = '/purchaserIdentity',
    purchaserPhoneVerificationRequest = '/purchaserPhoneVerificationRequest',
    purchaserPhoneVerify = '/purchaserPhoneVerify',
    representativeIdentity = '/representativeIdentity',
    representativeComplete = '/representativeComplete',
    sendIdentityLinkEmail = '/sendIdentityLinkEmail',
    unavailableIdentityFailed = '/unavailableIdentityFailed',
    travelContracts = '/travelContracts',
    funeralContracts = '/funeralContracts',
    contractsDenied = '/contractsDenied',
    purchaserSaleComplete = '/purchaserSaleComplete',
    purchaserSigningComplete = '/purchaserSigningComplete',
    purchaseProcessing = '/purchaseProcessing',
    purchaseProcessingNavigation = '/purchaseProcessingNavigation',
    purchaseProcessingBlank = '/purchaseProcessingBlank',
    docusignReroute = '/docusignReroute',
    recipientIdentity = '/recipientIdentity',
    recipientIdentityFailed = '/recipientIdentityFailed',
    recipientIdentityFailedHoldPlaced = '/recipientIdentityFailedHoldPlaced',
    recipientConsent = '/recipientConsent',
    recipientGratitude = '/recipientGratitude',
    recipientAbsent = '/recipientAbsent',
    vitalStatistics = '/vitalStatistics',
    vitalStatisticsSaved = '/vitalStatisticsSaved',
    expired = '/expired',
    prefilledRecipientIdentity = '/prefilledRecipientIdentity',
    prefilledRecipientConsent = '/prefilledRecipientConsent',
    prefilledRecipientGratitude = '/prefilledRecipientGratitude',
    purchaserOrderSummary = '/purchaserOrderSummary',
    noFundingProductAvailable = '/noFundingProductAvailable',
    underagePurchaser = '/underagePurchaser',
    underageRecipient = '/underageRecipient',
    stateFilingFee = '/stateFilingFee',
    earlyPriceListDisclaimer = '/earlyPriceList',
    middlePriceListDisclaimer = '/middlePriceList',
    latePriceListDisclaimer = '/latePriceList',
    noPaymentOptions = '/noPaymentOptions',
    recipientAvailabilityCheck = '/recipientAvailabilityCheck',
    ssnCapture = '/ssnCapture',
    verifyPurchaserIdentity = '/verifyPurchaserIdentity',
    trustFundedPrearrangement = '/trustFundedPrearrangement',
    startPlanning = '/startPlanning',
    opportunityStatus = '/opportunityStatus',
    // TODO remove page
    docusignFreeContractPage = '/docusignFreeContractPage',
    secureLinkSigning = '/secureLinkSigning',
    secureLinkSigningComplete = '/secureLinkSigningComplete',
    prefilledRecipientIdentityFailed = '/prefilledRecipientIdentityFailed',
    invalidContractSigningCredentials = '/invalidContractSigningCredentials',
    irrevocable = '/irrevocable',
    replacement = '/replacement'
}

export default urlPaths;
