import { PlaidIdentityStatus } from 'static/constants/enums/plaidIdentityStatus';
import GlobalStateShape from '../../../redux/GlobalStateShape';
import urlPaths from '../../../static/constants/enums/urlPaths';
import { PaymentMode } from 'static/constants/enums/paymentModes';

const recipientConsentReroutes = (state: GlobalStateShape): string => {
    if (
        state.opportunity?.recipient?.identityVerificationPlaid &&
        state.opportunity?.recipient?.identityVerificationPlaid !== PlaidIdentityStatus.SUCCESS
    ) {
        // If payment mode is null we default to true
        const hasPaymentModeAndValue = !state.opportunity?.pricing?.paymentOptions?.selectedOption?.paymentMode
            ? true
            : state.opportunity.pricing.paymentOptions.selectedOption.paymentMode === PaymentMode.Multi;
        if (hasPaymentModeAndValue && state.opportunity?.pricing?.paymentInitiated) {
            return urlPaths.recipientIdentityFailedHoldPlaced;
        }
        return urlPaths.recipientIdentityFailed;
    }
};

export default recipientConsentReroutes;
